@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}
.formMargins {
  margin-bottom: 24px;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.submitButton {
  margin-top: 3rem;
  margin-bottom: 6rem;
  flex-shrink: 0;
  border-radius: 25px;
  color: white;
  background: #6F00DE;
  /* padding: 15px 0; */
  border-color: #6F00DE;
  font-weight: var(--fontWeightBold);
  height:50px;
  /* height: 10px; */
  cursor: pointer;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    /* margin-top: 100px; */
  }
}
.submitButton:disabled{
  cursor: none;
}
.label{
  margin-bottom: 0;
}
.subLabel{
  font-weight: var(--fontWeightMedium);
}

.socials{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  margin-top: 12px;


  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.social{
  width: auto;
min-width: 300px;
  margin: 12px 10px;
  min-height: 55px;
@media (--viewportLarge) {
  width: 40%;
}
}

.socailButton{
  display: flex;
  align-items: center;
  padding: 10px 25px;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background: white;
  border: none;
  border-radius: 15px;
  padding-left: 25px;

font-weight: var(--fontWeightBold);
@media (--viewportLarge) {
  padding-left: 25px;

}

}
.socailButton:hover{
  cursor: pointer;
  border: 2px solid #6F00DE;

}
.socailButton a:hover{
  text-decoration: none;

}
a.socailButton:hover{
  text-decoration: none;

}
.icon{
  color:#6F00DE;
  font-size: 1.3rem;
  margin-right: 25px;

  @media (--viewportLarge) {

  }

}
.title {
  composes: formMargins;
  width: 100%;

}
.followers{
  composes: title;
  max-width: 250px;

}
