.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
}
.navHeading{
  margin: 0 24px;
}

.heading{
  margin-bottom: 0;
}
.text{
  margin-top: 0;

}