@import '../../styles/customMediaQueries.css';

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: stretch;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: #FFFFFF;

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: center;
      padding: 40px 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;
    flex-shrink: 0;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: 0;
    border-bottom: none;
    margin: 0;
    height: auto;
    min-height: 650px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    @media (--viewportMedium) {
      flex-basis: 576px;
      flex-grow: 0;
      min-height: auto;
      height: auto;
      width: 500px;
      max-width: 90vw;
      border-radius: 25px;
      margin: auto;
      padding: var(--modalPaddingMedium);
    }
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: flex;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
}

/* .closeText {
  composes: marketplaceModalCloseText from global;
} */

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}

.focusedDiv {
  &:focus {
    outline: none;
  }
}

.modalStyle {
  border-radius: 0;
  
  @media (--viewportMedium) {
    border-radius: 25px;
  }
}

.closeStyle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.5);
}
.closeP {
  position: relative; 
  bottom: 11px;
  
  @media (--viewportMedium) {
    position: relative; 
    bottom: 11px;
  }
}