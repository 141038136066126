@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  composes: marketplaceHeroTitleFontStyles from global;

  /* composes: animation; */

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    /* width: 544.89px; */
    /* height: 116px; */
    font-weight: 900;
    font-size: 50px;
    line-height: 58.25px;
    letter-spacing: -1.5%;
  }
}
.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitle {
  composes: h4 from global;

  margin: 0 0 32px 0;
  composes: animation;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 25px 0;
    /* width: 432.05px; */
    font-weight: 500px;
    font-size: 25px;
    line-height: 32.88px;
  }
}
.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButton {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  composes: animation;
  background-color: var(--blackColor);
  border-radius: 25px;
  min-height: 50px;
  margin-bottom: 0.5rem;

  @media (--viewportMedium) {
    display: block;
    width: 200px;
    margin-right: 0.5rem;
  }
  &:hover {
    background-color: var(--matterColorDark);
    /* color: var(--matterColorDark); */
    /* border-bottom: 4px solid var(--marketplaceColor); */
    /* border-radius: 0;
    text-decoration: none; */
  }
}
.heroButtonFEDelay {
  animation-delay: 0.3s;
}
.container {
  display: flex;
  flex-direction: column-reverse;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}
.left {
  width: 95%;
  margin-left: 1rem;
  margin-top: 2rem;
  @media (--viewportMedium) {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 3rem;
  }
}
.right {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 700px;
  max-width: 700px;
  @media (--viewportMedium) {
    width: 50%;
  }
}
.image {
  height: 200px;
  width: auto;
  aspect-ratio: 1 / 1;
  object-fit: contain;
  
  @media (--viewportMedium) {
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    padding: 4rem;
    padding-top: 10rem;
  }
}
.buttonRow {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Adjust the gap between buttons as needed */
}
.buttonContainer {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}
