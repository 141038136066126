@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  /* width: calc(34% - 9px); */
  padding-top: 15px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  /* composes: marketplaceModalPasswordMargins from global; */
  /* padding-top: 15px; */
  padding-bottom: 2rem;
}

.passwordMain {
  /* composes: marketplaceModalPasswordMargins from global; */
}

/* .bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
} */

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.emailIcon {
  padding-left: 28px;
  background: url("./Vectormsg.png") no-repeat left;
  background-size: 10px;
  background-position: 8px 20px;
}

.keyIcon {
  padding-left: 28px;
  background: url("./key-fillkey.png") no-repeat left;
  background-size: 12px;
  background-position: 8px 18px;
}

.usernameIcon {
  padding-left: 28px;
  background: url("./user-smile-fillusername.png") no-repeat left;
  background-size: 12px;
  background-position: 8px 18px;
}

.spamMsg {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  max-width: 285px;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.passwordMsg {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  max-width: 285px;
  height: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.uniqueUsername {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  width: 100%;
  max-width: 285px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* identical to box height */
}