/*
If you need to add css to the Logo you can do it here.

.logoMobile {
}
*/
.logo {
  color: var(--blackColor);
  font-weight: 700;
  font-size: 20px;
}
