.root {
  margin: 30px 0;
  padding: 0 24px;
}

.reviewAuthorTop {
  font-size: 14px;
  font-weight: 600;
  color: #555;
  margin: 0 0 8px 0;
  font-style: italic;
}

.title {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--matterColor);
}

.scrollContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.reviewsWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.reviewsContainer {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 10px 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scroll-snap-type: x mandatory;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.reviewsContainer::-webkit-scrollbar {
  display: none;
}

.gradientLeft,
.gradientRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80px;
  z-index: 1;
  pointer-events: none;
}

.gradientLeft {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.gradientRight {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.reviewCardWrapper {
  scroll-snap-align: start;
}

.reviewCard {
  flex: 0 0 auto;
  width: 300px;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0;
  height: 100%;
}

.reviewCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  border-color: var(--marketplaceColor);
}

.reviewContent {
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.reviewText {
  font-size: 16px;
  line-height: 1.5;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
}

.stars {
  margin-bottom: 12px;
  font-size: 18px;
}

.starFilled {
  color: var(--marketplaceColor);
}

.starEmpty {
  color: #e0e0e0;
}

.reviewFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewDate {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.reviewAuthor {
  font-size: 14px;
  font-weight: 600;
  color: var(--marketplaceColor);
  margin: 0;
  display: flex;
  align-items: center;
}

.scrollButton {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  color: var(--marketplaceColor);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.scrollButton:hover {
  background-color: var(--marketplaceColorLight);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  color: white;
}

.scrollButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(111, 0, 222, 0.25);
}

.scrollButtonLeft {
  left: 0;
  margin-right: 10px;
}

.scrollButtonRight {
  right: 0;
  margin-left: 10px;
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin: 0 5px;
  padding: 0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicatorActive {
  background-color: var(--marketplaceColor);
}

@media (max-width: 768px) {
  .root {
    padding: 0 16px;
    margin: 20px 0;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  
  .reviewCard {
    width: 260px;
    height: auto;
  }
  
  .reviewAuthorTop {
    font-size: 12px;
    margin-bottom: 6px;
  }
  
  .reviewText {
    -webkit-line-clamp: 3;
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .stars {
    margin-bottom: 6px;
    font-size: 16px;
  }
  
  .reviewFooter {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .reviewDate {
    font-size: 12px;
    margin-bottom: 4px;
  }
  
  .reviewAuthor {
    font-size: 12px;
    font-weight: 600;
  }
  
  .gradientLeft,
  .gradientRight {
    width: 40px;
  }
  
  .scrollButton {
    width: 36px;
    height: 36px;
    font-size: 20px;
  }
} 