.root {
  position: relative;
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 28px;
  background-color: #e8d1ff;
  border: 2px solid var(--marketplaceColor);

  &:focus {
    outline: none;
    background-color: #e8d1ff;
    border: 2px solid var(--marketplaceColor);
    text-decoration: none;
    color: white;
    box-shadow: var(--boxShadowFilterButton);
  }
}
.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 28px;
  background-color: #e8d1ff;
  border: 2px solid var(--marketplaceColor);
  color: var(--matterColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
    background-color: #e8d1ff;
  }
}
