@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
}
.formMargins {
  margin-bottom: 24px;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}
.label{
  margin-bottom: 0;
}
.subLabel{
  font-weight: var(--fontWeightMedium);
}
.submitButton {
  margin-top: 3rem;
  margin-bottom: 6rem;
  flex-shrink: 0;
  border-radius: 25px;
  color: white;
  background: #6F00DE;
  /* padding: 15px 0; */
  border-color: #6F00DE;
  font-weight: var(--fontWeightBold);
  height:50px;
  /* height: 10px; */
  cursor: pointer;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    /* margin-top: 100px; */
  }
}
.submitButton:disabled{
  cursor: unset;
}

.pricingMainContainer{
  display: flex;
  flex-wrap:wrap;

  margin: 45px 0;
  @media (--viewportMedium) {
    gap:45px
    /* margin-top: 100px; */
  }
}
.eachPriceEdit{
  border: none;
  border-radius: 15px;
  /* max-height: 500px; */
  min-height: 400px;
  width: 100%;
  margin-bottom: 35px;
  padding: 25px;
  padding-bottom: 70px;
  background-color: #fff;
  width: 100%;
  overflow: auto;
  @media (--viewportLarge) {
  max-width: 300px;

  width: 32%;

  }
}
.addButton{
  composes: eachPriceEdit;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  padding: 70px;
  cursor: pointer;


}
.addButtonIcon{
  margin-top: 42px;
  font-size: 105px;
  color:#6F00DE;
}
.bodCloseContainer{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
.descInput{
  background-color: #fff;
  /* width: min-content */

}
.titleInput{
  width: auto;
  

}
.title, .description{
  margin-bottom: 1rem;
}
.typeField{
  margin-bottom: 1rem;

}
.priceField{
  margin-bottom: 1rem;

}