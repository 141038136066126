.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* A high z-index */

  }
  
  .popupInner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%; /* Adjust width as needed */
    max-width: 600px; /* Maximum width */
    max-height: 90%; /* Maximum height */
    overflow: auto; /* Enables scrolling if content overflows */
    border-radius: 20px;
    background: white;
    padding: 20px;
    box-sizing: border-box; /* Ensures padding is included in width/height */
    text-align: center;
  }
  
  .closeButton {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .closeButton:hover {
    background-color: white;
    color: black;
    border: 2px solid #f44336;
  }
  
  h2 {
    color: #333;
  }
  
  p {
    color: #555;
  }