@import '../../styles/customMediaQueries.css';
:root {
  --chatWrapperWidth: min(95%, 900px);
}

.wrapper {
  background-color: #f5f5f5;
}

.header {
  position: sticky;
  top: 0;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  font-family: var(--fontFamily);
  color: #000;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  gap: 0.25em;
  margin: 0;
  z-index: 1;
  &:hover {
    cursor: pointer;
    color: var(--marketplaceColor);
  }
}

.headerIcon {
  font-size: 1.5rem;
}

.chatWrapper {
  display: flex;
  flex-direction: column;
  width: var(--chatWrapperWidth);
  margin: 0 auto;
  margin-top: 1rem;
  gap: 1rem;
  overflow: auto;
  height: 60vh;
  overflow-y: auto;
  padding: 1rem;
}

.commonMessageStyles {
  padding: 1rem 2rem;
  border-radius: 30px;
  width: min(100%, 400px);
  line-height: 21px;
  font-family: var(--fontFamily);
  display: grid;
  position: relative;

  grid-template-areas: 'name date' 'message message';
  & .message {
    line-height: 21px;
  }
  /* &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
  } */
}

.commonDateStyles {
  font-family: var(--fontFamily);

  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.ownMessage {
  /* background-color: var(--marketplaceColor); */
  /* color: #ffffff; */
  align-self: flex-end;
  width: min(100%, 400px);
  display: flex;
  flex-direction: row-reverse;
  gap: 1em;

  /* composes: commonMessageStyles; */
  & ::selection {
    background-color: #ededed;
    color: #000000;
  }
  /* &::after {
    border: 20px solid transparent;
    border-left-color: var(--marketplaceColor);
    border-right: 0;
    margin-top: -10px;
    margin-right: -10px;
  } */
  /* & .date {
    color: rgba(255, 255, 255, 0.5);
  } */
}

.innerOwnMessage {
  background-color: var(--marketplaceColor);
  color: #ffffff;
  composes: commonMessageStyles;
  & .date {
    color: rgba(255, 255, 255, 0.5);
  }
}

.otherMessage {
  width: min(100%, 400px);
  display: flex;
  /* flex-direction: row-reverse; */
  gap: 1em;

  /* composes: commonMessageStyles; */
  align-self: flex-start;
  /* background-color: #ededed; */
  /* color: #000000; */
  & ::selection {
    background-color: var(--marketplaceColor);
    color: #ffffff;
  }
  /* &::after {
    left: 0;
    border: 20px solid transparent;
    border-right-color: #ededed;
    border-left: 0;
    margin-top: -10px;
    margin-left: -10px;
  } */

  & .date {
    color: rgba(0, 0, 0, 0.5);
  }
}

.innerOtherMessage {
  composes: commonMessageStyles;
  background-color: #e2e2e2;
  color: #060505;
  & .date {
    color: rgba(11, 11, 11, 0.5);
  }
}

.date {
  composes: commonDateStyles;
  grid-area: date;
  justify-self: flex-end;
}
.name {
  grid-area: name;
  justify-self: flex-start;
  font-weight: 700;
}

.message {
  grid-area: message;
  margin: 0;
  margin-top: 0.25rem;
  text-align: left !important;
  color: #0c0c0c;
}

.sendMessageFormWrapper {
  width: var(--chatWrapperWidth);
  margin: 0 auto;
  padding: 2rem;
}

.showOlderWrapper {
  width: var(--chatWrapperWidth);
  margin: 0 auto;
  padding: 2rem 0 0 2rem;
  text-align: center;
  & .showOlderButton {
    font-weight: bold;
  }
}

.messageError {
  color: red;
  text-align: center;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.4;
  padding: 0 2rem;
}

.avatar {
  width: 45px !important;
  height: 40px !important;
  align-self: center;
  display: none;
  @media (--viewportMedium) {
    display: inline-flex;
  }
}

.uploadedImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
