.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.field{
  /* border-radius: 15px; */
  border: none;
}

/* .input {
  border-bottom-color: var(--attentionColor);
} */

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
  /* border: 3px solid #ececec;
    border-radius: 13px;
    background: #ececec;
    padding: 10px; */
    /* display: flex;
flex-direction: row;
align-items: center; */
padding: 16px 70px 16px 21px;
gap: 21px;
width: 350px;
height: 50px;
left: 785px;
top: 391px;
border: 1.5px solid #ECECEC;
background-color: #ECECEC !important;
border-radius: 10px;
}
/* .textarea:focus {
padding: 16px 70px 16px 21px;
gap: 21px;
width: 350px;
height: 50px;
left: 785px;
top: 391px;

background: #ECECEC;
border: 1.5px solid #6F00DE;
border-radius: 10px;
} */

.textarea:active {
  border: 1.5px solid #6F00DE ;
}

.textarea:focus {
  border: 1.5px solid #6F00DE ;
}