.root {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.messageInput {
  flex: 1;
  border-radius: 20px;
  line-height: 1.4;
  /* padding: 1rem; */
}

.sendButton {
  margin-left: 1rem;
  border: none;
  background-color: var(--marketplaceColor);
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: gray;
    color: darkgray;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    cursor: pointer;
    background-color: var(--marketplaceColorDark);
  }
}

.messageSendError {
  color: red;
  margin: 0;
  line-height: 1.4;
  margin-bottom: 0.25rem;
}

.loadingIcon {
  width: 20px;
  stroke: rgba(255, 255, 255, 0.7);
}

.sendButton[data-inprogress='true'] {
  background: var(--marketplaceColor) !important;
}

.uploadButton {
  border: none;
  background-color: var(--marketplaceColor);
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: gray;
    color: darkgray;
    cursor: not-allowed;
  }
  &:not(:disabled):hover {
    cursor: pointer;
    background-color: var(--marketplaceColorDark);
  }
}

.imageUploader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: none;
}
