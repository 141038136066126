@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;
  margin-top: 20px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
  width: 100%;

}
.gender{
  composes: title;
  max-width: 250px;

}

.calendarIcon {
  padding-left: 42px;
  background: url("./calendar.png") no-repeat center ;
  padding-bottom: 10px;
  
  background-size: 18px;
  background-position: 8px 20px;
}

.docIcon {
  padding-left: 42px;
  padding-bottom: 10px;
  background: url("./document.svg") no-repeat center;
  background-size: 18px;
  background-position: 8px 18px;
}

.addPersonIcon {
  padding-left: 42px;
  padding-bottom: 10px;

  background: url("./person.png") no-repeat center;
  background-size: 18px;
  background-position: 8px 18px;
  border: 1.5px solid #ECECEC;
  background-color: #ECECEC ;
}


.description {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: 6rem;
  margin-bottom: 10rem;
  flex-shrink: 0;
  border-radius: 25px;
  color: white;
  background: #6F00DE;
  /* padding: 15px 0; */
  border-color: #6F00DE;
  font-weight: var(--fontWeightBold);
  height:50px;
  /* height: 10px; */
  cursor: pointer;
  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    /* margin-top: 100px; */
  }
}
.submitButton:disabled{
  cursor:unset;
}
.label{
  margin-bottom: 0;
}
.subLabel{
  font-weight: var(--fontWeightMedium);
}


.imagesField {
  flex-shrink: 0;
  margin: 0;
  margin-top: 32px;
  margin-bottom: 20px;
}

.thumbnail {
  margin: 24px 0 0 0;
  border-radius: 5px;
  @media (--viewportLarge) {
    width: calc(25% - 12px);
    margin: 0 24px 24px 0;

    /* &:nth-child(even) {
      margin: 0 0 24px 0;
    } */
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(25% - 12px);
    
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }
  background-color: #ECECEC;
  padding-top: 100px;
  background: url("./clip.svg") no-repeat center #ECECEC;
  

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 10px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    /* border-color: var(--matterColorAnti); */
  }
  border: none;

}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.error {
  color: var(--failColor);
}
.addImageButton{
  background: url("./clip.svg") ;

}
.locationAddress{
  width: 100%;
  @media (--viewportLarge) {
    max-width: 500px;
    width:auto
  }
}
.mapContainer {
  /* height: auto; */
  height: 283px;
  border-radius: 15px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
  margin-top: 2rem;
  width: 80%;
}
.mapsContainer {
  /* height: auto; */
  border-radius: 15px;

  height: 100%;
  width: 100%;
 }
 .usernameIcon {
  padding-left: 28px;
  background: url("./user-smile-fillusername.png") no-repeat left;
  background-size: 12px;
  background-position: 8px 18px;
  border: 1.5px solid #ECECEC;
  background-color: #ECECEC ;
}