@import '../../styles/customMediaQueries.css';
.rootForImage {
  width: 100%;
  height: 100%;
  background-size: cover;
  object-fit: cover; /* This is for larger screens */
}

/* Media query for mobile devices */
@media (max-width: 768px) { /* Adjust this breakpoint as needed */
  .rootForImage {
    object-fit: contain; /* Changes to 'contain' on mobile devices to ensure the image fits within its container */
    background-size: contain; /* Use this if you're dealing with background images */
    background-position: center; /* Center the background image within the container */
    background-repeat: no-repeat; /* Prevent the background image from repeating */
  }
}

.product {
  /* background-color: #fff; */
  /* border: 1px solid #e7edf1; */
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.12); */
  /* box-shadow: 0 0px 0px rgb(0 0 0 / 8%), 0 0px 8px rgb(0 0 0 / 12%); */
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid black; */
  border-radius: 15px;
  margin: 0rem 0.75rem;
  min-width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  
  @media (--viewportSmall) {
    min-width: 46%;
    max-width: 46%;
  }
  @media (--viewportMedium) {
    min-width: 248px;
    max-width: 248px;
  }

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.productImageWrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  height: 260px;
  @media (--viewportSmall) {
    height: 230px;
  }
  & img {
    transition: all 200ms ease;
    /* border-radius: 10px 10px 0px 0px; */
  }
  &:hover {
    & img {
      transform: scale(1.1);
      /* border-radius: 10px 10px 0px 0px; */
    }
  }

  & p:first-of-type {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    margin: 0;
    color: #fff;
    font-size: 0.8rem;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.25);
  }
}

.productBodyWrapper {
  /* padding: 0 1rem; */
  & h3 {
    /* font-size: 0.8rem; */
    /* color: #999; */
    /* font-weight: normal; */
    line-height: 1.4em;
    /* margin: 0; */
    /* text-overflow: ellipsis; */

    /* Needed to make it work */
    /* overflow: hidden; */
    /* white-space: nowrap; */
  }
}

.productPrice {
  & p {
    margin: 0;
    margin-top: 0.5rem;
    color: #111;
    font-size: 0.8rem;
    font-weight: 600;
  }
}
.co2Container {
  display: flex;
  justify-content: space-between;
}

.star {
  fill: orange;
}
.price {
  color: var(--marketplaceColor);
  font-weight: 600;
  margin: 0;
}
.title {
  margin: 0;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 700;
}
.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.container {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  line-height: 40px;
}
.genderContainer {
  display: flex;
  margin: 0px 10px;
  margin-top: -0.5rem;
}
.iconContainerMain {
  display: flex;
  justify-content: space-between;
  margin: 0px 10px;
  margin-bottom: 0.5rem;
}
.iconContainer {
  display: flex;
}
.gender {
  margin: 0;
  text-transform: capitalize;
}
.text {
  margin: 0px 10px;
}
.map {
  border-radius: 10px;
  height: 70px;
  margin: 0px 10px;
  margin-bottom: 0.025rem;
}
.mapContainer {
  height: 70px;
  width: 100%;
  border-radius: 10px;
}
.icon {
  fill: var(--marketplaceColor);
  margin: 0.25rem;
  font-size: 1.8rem;
  
}
.largeIcon {
  fill: var(--marketplaceColor);
  font-size: 25px;
  margin: 0.25rem;
}
.star {
  fill: var(--attentionColor);
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}
.age {
  margin-left: 0.25rem;
  color: var(--attentionColor);
  font-size: 14px;
}
.address {
  font-weight: 600;
}
.lastContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
